<template>
  <div class="csn-horizontal-list csn-slide-show">
    <div :class="gameListClass">
      <button @click="handleLeftButtonClick" :class="leftButtonClass">
        <SlideArrow :isBack="true" />
      </button>
      <button @click="handleRightButtonClick" :class="rightButtonClass">
        <SlideArrow />
      </button>
      <div class="csn-slider">
        <slider :ref="ref" :options="options">
          <slideritem
            v-for="({ imagePath, style }, index) in list"
            :key="index"
            class="csn-slider-item"
          >
            <div :style="style" class="csn-slider-item-background-img" />
            <img
              v-if="imagePath"
              class="csn-slider-item-img"
              :src="imagePath"
              alt="slider_image"
            />
          </slideritem>
          <div slot="loading">
            <div class="csn-slider-loader-container">
              <Loader />
            </div>
          </div>
        </slider>
      </div>
    </div>
  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
import {
  SLIDE_SHOW,
  Digit,
  EMPTY_STRING,
  CARUSEL_WRAPPER,
  CSN_HORIZONTAL_GAME_LIST,
  CSN_HORIZONTAL_GAME_LIST_HIGH,
  SLIDER,
  SLIDE_PRE,
  SLIDE_NEXT,
  CSN_HORIZONTAL_GAME_LIST_BUTTON_LEFT,
  CSN_HORIZONTAL_GAME_LIST_BUTTON_RIGHT,
  CSN_HORIZONTAL_GAME_LIST_BUTTON_HIDDEN,
} from '@/constants'
import { generateUUID } from '@/helpers'

const options = {
  currentPage: Digit.NOUGHT,
  autoplay: Digit.FIVE_THOUSAND,
  loop: true,
}

export default {
  name: SLIDE_SHOW,
  components: {
    slider,
    slideritem,
    Loader: () => import('@/components/Loader'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
  },
  props: {
    list: [() => Array, () => Object],
  },
  data: () => ({
    showsLeftButton: true,
    showsRightButton: true,
  }),
  computed: {
    ref: () => `${SLIDER}${generateUUID()}`,
    options() {
      return options
    },
    gameListClass() {
      return [
        CARUSEL_WRAPPER,
        CSN_HORIZONTAL_GAME_LIST,
        CSN_HORIZONTAL_GAME_LIST_HIGH,
      ]
    },
    leftButtonClass() {
      return [
        CSN_HORIZONTAL_GAME_LIST_BUTTON_LEFT,
        this.showsLeftButton
          ? EMPTY_STRING
          : CSN_HORIZONTAL_GAME_LIST_BUTTON_HIDDEN,
      ]
    },
    rightButtonClass() {
      return [
        CSN_HORIZONTAL_GAME_LIST_BUTTON_RIGHT,
        this.showsRightButton
          ? EMPTY_STRING
          : CSN_HORIZONTAL_GAME_LIST_BUTTON_HIDDEN,
      ]
    },
  },
  methods: {
    handleLeftButtonClick() {
      this.$refs[this.ref].$emit(SLIDE_PRE)
    },
    handleRightButtonClick() {
      this.$refs[this.ref].$emit(SLIDE_NEXT)
    },
  },
}
</script>

<style scoped></style>
